import React, { useState, useEffect } from "react";
import Navigation from "./../components/Navigation/Navigation";
import { Row, Breadcrumb, Col, Badge } from "react-bootstrap";
import Footer from "./Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getStudioGames, updateGame, markGameFeatured, unmarkGameFeatured} from "../service/actions/gameActions";
import GameList from "./GameList";
import DashboardStats from "../components/common/dashboardStats";
import http from "../utils/Api";

const GamePage = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const games = useSelector((state) => state.game);
  console.log(games,'THE GAMES')
  const token = localStorage.getItem("accessToken");
  const usersPermission = useSelector((state) => state.userPermission);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudioGames());
  }, []);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  // Sort the games array by a specific property (e.g., packageName)
  const sortedGames = games.slice().sort((a, b) => {
    // Replace 'packageName' with the property you want to sort by
    return a.packageName.localeCompare(b.packageName);
  });

  const onToggleState = (game) => {
    dispatch(updateGame(game));
  };

  // const onMarkGame = (game) => {
  //   dispatch(markGameFeatured(game));

  // }
  // const onUnMarkGame = (game) => {
  //   dispatch(unmarkGameFeatured(game))
  // }

  useEffect(() => {
    const myInterval = setInterval(() => {
      setLoading(false);
    }, 1000);


    return () => {
      clearInterval(myInterval);
    };
  }, []);

  let loader = null;
  
 
  const [impressionData, setImpressionData] = useState({
    totalLaunches: 0,
    averageGameLaunchersPerUser: 0,
    totalFeaturedLaunches: 0,
    averageFeaturedGameLaunchersPerUser: 0,
  });

  // const impressionStatsData = async ()=>{
  //   const token = localStorage.getItem("accessToken");
  //   try{

  //     const res = await http.get(`/api/admin/impressionperuser`,
  //     http.generateConfig(token)
  //     )
  //     if (res && res.result) {
  //       setLaunchData({ ...res.result });
  //     }
  //   }
  //   catch(error){
  //     console.error("Error fetching system data:", error);
  //     setLoading(false); 
  //   }
  // }

  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Game</h1>
            </Breadcrumb>
          </div>
          <div>
          

          </div>
          <div
            className="max-width-container"
            style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}
          >
            <Row>
              <Col md={12} >
                <GameList games={sortedGames} onToggleState={onToggleState}  />
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default GamePage;
