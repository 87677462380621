import { allTask, updateTask } from "../actionTypes/types";

const taskReducer = (state = [], action) => {
  switch (action.type) {
    case allTask:
      return action.payload;
    case updateTask:
      return state.map((task) =>
        task.id === action.payload.id ? action.payload : task
      );
    default:
      return state;
  }
};

export default taskReducer;
