import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Spinner, Row, Col, FormControl } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import { getAllTask, updateTaskAction } from "../service/actions/taskActions";

import Navigation from "../components/Navigation/Navigation";
import ResponsiveTable from "../components/common/Table";
import EditFieldModal from "../components/modal/EditFieldModal";

const Tasks = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // Store selected row
  const [selectedField, setSelectedField] = useState(""); // Store selected field
  const [title, setTitle] = useState(""); // Store selected field
  const [fieldValue, setFieldValue] = useState(""); // Store field value being edited
  const [currentPage, setCurrentPage] = useState(0);
  const [filterDataRow, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    description: "",
    rewardText: "",
    tooltips: "",
    total: "",
    type: "",
  });

  const itemsPerPage = 10;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTask()).then(() => {
      setLoading(false);
    });
  }, []);

  const task = useSelector((state) => state.task);
  console.log(task.length);
  const filteredTask = task.filter((row) => {
    const descriptionMatch =
      !filters.description || // If no filter applied
      row.description
        ?.toLowerCase()
        .includes(filters.description.toLowerCase()) ||
      "";

    const rewardTextMatch =
      !filters.rewardText || // If no filter applied
      row.rewardText
        ?.toLowerCase()
        .includes(filters.rewardText.toLowerCase()) ||
      "";

    const tooltipsMatch =
      !filters.tooltips || // If no filter applied
      row.tooltips?.toLowerCase().includes(filters.tooltips.toLowerCase()) ||
      "";

    const totalMatch =
      !filters.total || // If no filter applied
      row.total?.toString().includes(filters.total) ||
      "";

    const typeMatch =
      !filters.type || // If no filter applied
      row.type?.toLowerCase().includes(filters.type.toLowerCase()) ||
      "";

    return (
      descriptionMatch &&
      rewardTextMatch &&
      tooltipsMatch &&
      totalMatch &&
      typeMatch
    );
  });

  const handleFilterChange = (field) => (e) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
  };

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const handleSaveField = () => {
    const data = { [selectedField]: fieldValue };
    const id = selectedRow.id;
    dispatch(updateTaskAction(data, id)).then(() => {
      setOpenEditModal(false); // Close modal after saving
    });
  };

  const handleOpenModal = (row, key, label) => {
    setSelectedRow(row);
    setSelectedField(key);
    setTitle(label);
    setFieldValue(row[key]);
    setOpenEditModal(true);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    console.log("Selected Page:", selectedPage); // Add this log
    setCurrentPage(selectedPage);
  };
  const pageCount = Math.ceil(task.length / itemsPerPage);

  const indexOfLastUser = currentPage * itemsPerPage;
  const currentTask = filteredTask.slice(
    indexOfLastUser,
    indexOfLastUser + itemsPerPage
  );

  const columns = [
    { label: "ID", minWidth: "80px", key: "id" },
    {
      label: "Description",
      minWidth: "120px",
      key: "description",
      type: "task_description",
      click: handleOpenModal,
    },
    {
      label: "Reward Type",
      minWidth: "150px",
      key: "rewardType",
    },
    {
      label: "Reward Text",
      minWidth: "100px",
      key: "rewardText",
      type: "task_rewardText",
      click: handleOpenModal,
    },
    {
      label: "Tooltips",
      minWidth: "120px",
      key: "tooltips",
      type: "task_tooltip",
      click: handleOpenModal,
    },
    { label: "Total Hours", minWidth: "120px", key: "total" },
    { label: "Type", minWidth: "80px", key: "type" },
  ];

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />

      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        {loading && loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="main-content-header">
              <Breadcrumb>
                <h1>Tasks</h1>
              </Breadcrumb>
            </div>
            <div
              className="pagination-parent"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                activeLinkClassName={"active"}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                paddingLeft: "14px",
                paddingRight: "12px",
              }}
            >
              <FormControl
                type="text"
                placeholder="Filter by Description"
                value={filters.description}
                onChange={handleFilterChange("description")}
              />
              <FormControl
                type="text"
                placeholder="Filter by Reward Text"
                value={filters.rewardText}
                onChange={handleFilterChange("rewardText")}
              />
              <FormControl
                type="text"
                placeholder="Filter by Tooltips"
                value={filters.tooltips}
                onChange={handleFilterChange("tooltips")}
              />
              <FormControl
                type="text"
                placeholder="Filter by Total"
                value={filters.total}
                onChange={handleFilterChange("total")}
              />
              <FormControl
                type="text"
                placeholder="Filter by Type"
                value={filters.type}
                onChange={handleFilterChange("type")}
              />
            </div>

            <div
              className="max-width-container"
              style={{ width: "100%", maxWidth: "100%" }}
            >
              {currentTask.length > 0 ? (
                <Row>
                  <Col md={12}>
                    <ResponsiveTable data={currentTask} columns={columns} />
                  </Col>
                </Row>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "24px",
                  }}
                >
                  No record found
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <EditFieldModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        title={`Edit ${title}`}
        bodyContent={
          <div className="form-container">
            <label className="form-label">{title}</label>
            <input
              className="form-input"
              type="text"
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
            />
          </div>
        }
        actionButtonText="Save"
        onAction={handleSaveField} // Save changes
      />
    </div>
  );
};

export default Tasks;
