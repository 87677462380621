import React, { useEffect, useMemo, useState } from "react";
import {
  Breadcrumb,
  Col,
  Row,
  Button,
  Table,
  Container,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/common/Loader";
import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import http from "../utils/Api";
import * as Icon from "react-feather";
import DashboardStats from "../components/common/dashboardStats";
import {
  getAllRewardsAc,
  updateRewardStatus,
  updateRewardTransacitonId,
} from "../service/actions/rewardAction";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import DAUChart from "../components/charts/DAUCharts";

const Reward = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();


  const [systemData, setSystemData] = useState({
    totalRewardGiven: 0,
    totalRewardUsdValue: 0,
    weeklyLimit: 0,
    weeklyRemainingLimit: 0,
    todayEarnings: 0,
    playerDailyLimit: 0,
    withdrawlLimit: 0,
    tokenLimit: 0,
    totalOptInPoints : 0,
    totalPlayerOptIn : 0

  });

  

 
  // State for cancellation modal
  const [showModal, setShowModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [selectedRewardId, setSelectedRewardId] = useState(null);
  const [editedTransactionId, setEditedTransactionId] = useState("");
  const [isEditingTransactionId, setIsEditingTransactionId] = useState(false);
  const [editedRewardId, setEditedRewardId] = useState(null); // Track the currently edited reward ID

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(5);
  useEffect(() => {
    // Fetch system data when the component mounts
    getSystemData();
    // getDailyUser();
  }, []);

  useEffect(() => {
    dispatch(getAllRewardsAc(currentPage,)).then((response) => setLastPage(response))
  }, [currentPage])

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [dailyUsers, setDailyUsers] = useState([])

 

  // JSX for pagination controls
  const pageNumbers = [];
  for (let i = 1; i <= lastPage; i++) {
    pageNumbers.push(i);
  }

  // Calculate the range of page numbers to display
  let startPage = Math.max(1, Math.min(currentPage - 4, lastPage - 9));
  let endPage = Math.min(startPage + 9, lastPage);

  // Adjust startPage if endPage is at the maximum limit
  startPage = Math.max(1, endPage - 9);

  // JSX for pagination controls with arrows and centered alignment
  const renderPageNumbers = (
    <ul className="pagination justify-content-center">
      {/* Previous page arrow */}
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
        <button onClick={() => paginate(currentPage - 1)} className="page-link" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>

      {/* Render page numbers */}
      {Array.from({ length: (endPage - startPage) + 1 }, (_, index) => startPage + index).map((number) => (
        <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
          <button onClick={() => paginate(number)} className="page-link">
            {number}
          </button>
        </li>
      ))}

      {/* Next page arrow */}
      <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
        <button onClick={() => paginate(currentPage + 1)} className="page-link" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  );


  const getSystemData = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        "/api/admin/appStatistics",
        http.generateConfig(token)
      );
      if (res && res.result) {
        setSystemData({ ...res.result });
        // setEditedWeeklyLimit(res.result.weeklyLimit); // Initialize the edited value
        setLoading(false); // Mark loading as complete
      }
    } catch (error) {
      setLoading(false); // Mark loading as complete even if there's an error
    }
  };

  const onSideMenu = (active) => {
    setSideMenu(active);
  };
  
  // const getDailyUser = async () => {
  //   const token = localStorage.getItem("accessToken");
  //   try{
  //   const res = await http.get("/api/admin/getDailyActiveUsers", 
  //       http.generateConfig(token)
        
  //     );
  //     console.log(res)

  //     if(res ){
  //       setDailyUsers(res)
  //       setLoading(false)
  //     }
  //     console.log("daily users", dailyUsers)

  //   }catch (error){
  //     console.error("error fetching data", error)
  //     setLoading(false)
      
  //   }
  // }

  const allRewards = useSelector((state) => state.reward);
  const handleStatusUpdate = (rewardId, status) => {
    if (status === "cancelled") {
      // Set the selectedRewardId before showing the modal
      setSelectedRewardId(rewardId);
      setShowModal(true);
    } else {
      // For other statuses, proceed with the status update
      // (you can dispatch an action here)
      dispatch(updateRewardStatus({ id: rewardId, status }));
    }
  };

  const saveCancellation = () => {
    // Handle the cancellation reason

    // Dispatch an action to update the reward status
    dispatch(
      updateRewardStatus({
        id: selectedRewardId,
        status: "cancelled",
        statusReason: cancellationReason,
      })
    );

    // Close the modal
    setShowModal(false);
  };

  const handleEditTransactionId = (rewardId, currentTransactionId) => {
    // Enable editing mode for Transaction ID
    setIsEditingTransactionId(true);
    setEditedRewardId(rewardId); // Set the currently edited reward ID
    // Set the edited value to the current Transaction ID
    setEditedTransactionId(currentTransactionId);
  };

  const handleSaveTransactionId = () => {
    // Find the index of the reward to update
    const rewardIndex = allRewards.findIndex(
      (reward) => reward.id === editedRewardId
    );

    if (rewardIndex !== -1) {
      // Create a copy of the array to avoid mutating the state directly
      const updatedRewards = [...allRewards];

      // Update the specific reward's transactionId
      updatedRewards[rewardIndex] = {
        ...updatedRewards[rewardIndex],
        transactionId: editedTransactionId,
      };

      const data = {
        out_id: updatedRewards[rewardIndex].id,
        player_id: updatedRewards[rewardIndex].Player.id,
        transaction_id: updatedRewards[rewardIndex].transactionId,
      };
      // Dispatch an action to update the specific reward
      dispatch(updateRewardTransacitonId(data)); // Assuming you have an updateReward action

      // Disable editing mode
      setIsEditingTransactionId(false);
      setEditedRewardId(null);
    }
  };

  // States for search inputs
  const [searchPlayer, setSearchPlayer] = useState("");
  const [searchRecievingChain, setSearchRecievingChain] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  // Function to filter rewards based on search inputs
  const filteredRewards = allRewards.filter((reward) => {
    const playerInfo = reward.Player?.email || reward.Player?.mobile;
    const recievingChain = reward.receivingBlockchain || "";
    const status = reward.status?.toLowerCase() || "";

    const playerMatch =
      playerInfo?.toLowerCase().startsWith(searchPlayer.toLowerCase()) ||
      !searchPlayer; // Include all if searchPlayer is empty
    const recievingChainMatch =
      recievingChain
        .toLowerCase()
        .includes(searchRecievingChain.toLowerCase()) || !searchRecievingChain;
    const idMatch =
      reward.id?.toString().startsWith(searchId.toLowerCase()) || !searchId;
    const statusMatch =
      status.includes(searchStatus.toLowerCase()) || !searchStatus;

    // Combine the search conditions as needed
    return playerMatch && recievingChainMatch && idMatch && statusMatch;
  });

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }


  // const formatDecimal = (number) => {
  //   return parseFloat(number).toFixed(3); 
  // };
  const copyToClipboard = (text) => {
    // Attempt to use navigator.clipboard API
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // You may show a success message or perform additional actions
        })
        .catch((error) => {
          // You may handle the error and show an error message
        });
    } else {
      // Fallback: Use a textarea element for manual copying
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      // You may show a success message or perform additional actions
    }
  };

  
  const chartData = dailyUsers.map((player) => ({
    x: new Date(player.date).getTime(),
    y: player.dau,
  }));
  const chartOptions = {
    chart: {
      id: "line-chart",

    },
    xaxis: {
      type: "datetime",
    },
    stroke: {
      curve: "smooth",
      colors: ["#04542D"],
      width: 4,
      show: true,
      dashArray: 0,
    },

  };

  const chartSeries = [
    {
      name: "Daily Active Users",
      data: chartData,
    },
  ];
  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      {/* End Navigation */}

      <div
        className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
          }`}
      >
        {/* Loader */}


        {/* End Loader */}


        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Reward</h1>
          </Breadcrumb>
        </div>
        {/* End Breadcrumb */}
        {loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}

        <div
          className="max-width-container"
          style={{ width: "100%", maxWidth: "100%", margin: "0 auto" }}
        >
          <Row>
            <DashboardStats
              statistics={[
                {
                  title: "Total Rewarded",
                  count: systemData.totalRewardGiven.toFixed(3),
                },
                {
                  title: "Total Reward Dollar Value",
                  count: systemData.totalRewardUsdValue.toFixed(2),
                },
                // {
                //   title: "Weekly Reward Limit",
                //   count: systemData.weeklyLimit,
                // },
              ]}
            />
          </Row>
          
          <Row>
            <DashboardStats
              statistics={[
                {
                  title: "Total Opt-In Players",
                  count: systemData.totalPlayerOptIn.toFixed(3),
                },
                {
                  title: "Total Opt-In Points",
                  count: systemData.totalOptInPoints.toFixed(2),
                },
                // {
                //   title: "Weekly Reward Limit",
                //   count: systemData.weeklyLimit,
                // },
              ]}
            />
          </Row>
         
          <Row>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-4 mb-2"
            >
              <p
                style={{
                  fontSize: "30px",
                  color: "#04542D",
                  fontWeight: 600,
                  paddingLeft: "5px",
                  padding: "20px",
                }}
              >
                {systemData.weeklyRemainingLimit} / {systemData.weeklyLimit}
              </p>
              <ProgressBar
                now={
                  (systemData.weeklyRemainingLimit / systemData.weeklyLimit) *
                  100
                }
                label={`${systemData.weeklyRemainingLimit}/${systemData.weeklyLimit}`}
                variant="success"
                className="mb-2"
                style={{ width: "50%", margin: "0 auto", height: "20px" }}
              />
              <p
                style={{
                  fontSize: "15px",
                  color: "#04542D",
                  fontWeight: 600,
                  paddingBottom: "5px",
                }}
              >
                ({systemData && systemData.weeklyRemainingLimit && systemData.weeklyLimit ?
                  Math.round((systemData.weeklyRemainingLimit / systemData.weeklyLimit) * 100) :
                  0
                }% remaining)

              </p>
            </Col>
          </Row>
          <Row>

          <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"
            >
              <DAUChart />
            </Col>
            
          </Row>

          <Row>

            <Container
              fluid
              className="p-2 mt-5"
              style={{ backgroundColor: "#fff", borderRadius: "15px" }}
            >
              <h5>Checkouts</h5>
              {renderPageNumbers}

              <div style={{ display: "flex", marginBottom: "15px" }}>
                {/* Search Inputs */}
                <Form.Control
                  type="text"
                  placeholder="Search ID..."
                  value={searchId}
                  onChange={(e) => setSearchId(e.target.value)}

                />
                <Form.Control
                  type="text"
                  placeholder="Search Player..."
                  value={searchPlayer}
                  onChange={(e) => setSearchPlayer(e.target.value)}

                />
                <Form.Control
                  type="text"
                  placeholder="Search Receiving Chain..."
                  value={searchRecievingChain}
                  onChange={(e) => setSearchRecievingChain(e.target.value)}

                />
                <Form.Control
                  as="select"
                  value={searchStatus}
                  onChange={(e) => setSearchStatus(e.target.value)}

                >
                  <option value="">Search Status...</option>
                  <option value="pending">Pending</option>
                  <option value="complete">Complete</option>
                  <option value="cancelled">Cancelled</option>
                </Form.Control>
              </div>

              {filteredRewards.length > 0 ? (
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                  <Table
                    hover
                    responsive
                    className="text-center"
                    style={{ margin: "0 auto" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>id</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Player</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Total Reward Sent</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Total Reward USD</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Receiving Chain</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Amount</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Created At</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Wallet Address</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>To Send</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Fraud Reason</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Reason</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Status</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Transaction Id</th>
                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>Suspicious</th>

                      </tr>
                    </thead>
                    <tbody>
                      {filteredRewards.map((reward, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                            }}
                          >
                            {reward.id}
                          </td>

                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",

                            }}
                          >
                            {reward.Player.email
                              ? reward.Player.email
                              : reward.Player.mobile}
                          </td>
                          <td>{reward.totalTokenSend}</td>
                          <td>{reward?.totalTokenSendUSD.toFixed(3)}</td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                            }}
                          >
                            {reward.receivingBlockchain}
                          </td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                            }}
                          >
                            {reward.total}
                          </td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {formatDate(reward.createdAt)}
                          </td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                              padding: "5px 20px"
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  width: "150px", // Set the fixed width
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                                  fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                {reward.wallet}
                              </div>
                              <Icon.Copy
                                onClick={() => copyToClipboard(reward.wallet)}
                                color="blue"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                            }}
                          >
                            {reward.amountToSend}
                          </td>
                          <td>
                            {
                              reward.Player.playerFraudReasons && reward.Player.playerFraudReasons.length > 0 ?
                                (<>{reward.Player.playerFraudReasons[0].reason}</>) : (<>{'-'}</>)
                            }
                          </td>
                          <td>{reward.statusReason || "-"}</td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                            }}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                variant={
                                  reward.status === "pending"
                                    ? "warning"
                                    : reward.status === "complete"
                                      ? "success"
                                      : reward.status === "cancelled"
                                        ? "danger"
                                        : reward.status === "fraud"
                                          ? "danger"
                                          : "primary"
                                }
                                id={`dropdown-status-${index}`}
                              >
                                {reward.status}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {reward.status !== "complete" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "complete")
                                    }
                                  >
                                    Mark as Complete
                                  </Dropdown.Item>
                                )}
                                {reward.status !== "pending" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "pending")
                                    }
                                  >
                                    Mark as Pending
                                  </Dropdown.Item>
                                )}
                                {reward.status !== "cancelled" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "cancelled")
                                    }
                                  >
                                    Mark as Cancelled
                                  </Dropdown.Item>
                                )}
                                {reward.status !== "fraud" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "fraud")
                                    }
                                  >
                                    Mark as Fraud
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>

                          <td>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  width: "150px",
                                  overflow: "scroll",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: reward?.Player.warning
                                    ? "red"
                                    : "grey",
                                  fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                {isEditingTransactionId &&
                                  editedRewardId === reward.id ? (
                                  <>
                                    <input
                                      type="text"
                                      value={editedTransactionId}
                                      onChange={(e) =>
                                        setEditedTransactionId(e.target.value)
                                      }
                                    />
                                    <Icon.Save
                                      variant="success"
                                      onClick={handleSaveTransactionId}
                                      style={{
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                      }}
                                      onMouseEnter={(e) =>
                                        (e.target.style.color = "blue")
                                      }
                                      onMouseLeave={(e) =>
                                        (e.target.style.color = "initial")
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    {reward?.transaction_id ? (
                                      <span>{reward.transaction_id}</span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                    <Icon.Copy
                                      onClick={() =>
                                        copyToClipboard(reward.transaction_id)
                                      }
                                      color="blue"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <Icon.Edit
                                      variant="primary"
                                      onClick={() =>
                                        handleEditTransactionId(
                                          reward.id,
                                          reward.transaction_id
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        transition: "color 0.3s",
                                      }}
                                      onMouseEnter={(e) =>
                                        (e.target.style.color = "blue")
                                      }
                                      onMouseLeave={(e) =>
                                        (e.target.style.color = "initial")
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          <td style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                              fontWeight: reward?.Player.warning || reward?.Player.isSuspicious
                                ? "bold"
                                : "normal",
                            }}>
                            {reward?.Player.isSuspicious ? "True" : "False"}

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {showModal && (
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Provide Cancellation Reason</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group controlId="cancellationReason">
                            <Form.Label>Reason for Cancellation</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={cancellationReason}
                              onChange={(e) =>
                                setCancellationReason(e.target.value)
                              }
                              placeholder="Enter the reason for cancellation..."
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Cancel
                        </Button>
                        <Button variant="primary" onClick={saveCancellation}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
              ) : (
                <>no reward</>
              )}
            </Container>
          </Row>
        </div>

        {/* Footer */}
        <div className="flex-grow-1"></div>
        <Footer />
        {/* End Footer */}
      </div>
    </div>
  );
};

export default Reward;
