import React from "react";
import { Table, Container } from "react-bootstrap";
import { FaEdit } from "react-icons/fa"; // Import the edit icon

// Reusable Table component
const ResponsiveTable = ({ columns, data }) => {
  return (
    <Container fluid>
      <Table
        className="mx-1 align-middle"
        bordered
        hover
        responsive
        style={{
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          maxWidth: "99%",
        }}
      >
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th
                key={index}
                style={{ minWidth: `${column.minWidth}`, maxWidth: "350px" }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data?.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {columns?.map((column, colIndex) => (
                  <td key={colIndex} className="text-capitalize">
                    {column.type === "task_description" ||
                    column.type === "task_tooltip" ||
                    column.type === "task_rewardText" ? (
                      <div className="d-flex">
                        {row[column.key]}
                        <span className="ms-2 aligns-items-center">
                          <FaEdit
                            className="ms-1"
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              fontSize: "24px",
                            }}
                            onClick={() =>
                              column.click(row, column.key, column.label)
                            }
                          ></FaEdit>
                        </span>
                      </div>
                    ) : column.key === "rewardType" ? (
                      row?.rewardBooster?.rewardType
                    ): column.key === "total" ? (
                      row?.total + " " + row?.measurement
                    )
                     :
                     (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default ResponsiveTable;
