import React, { useState, useEffect } from "react";
import Navigation from "./../components/Navigation/Navigation";
import { Row, Breadcrumb, Col, Container, Table } from "react-bootstrap";
import Footer from "./Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getStudioGames } from "../service/actions/gameActions";
import http from "../utils/Api";
import FormControl from "react-bootstrap/FormControl";

import DashboardStats from "../components/common/dashboardStats";
import tokenImage from "../assets/img/token.png";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Dashboard = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [overallStats, setOverallStats] = useState({
    totalGames: 0,
    totalPlayers: 0,
    totalDuration: 0,
  });
  const [enablePan, setEnablePan] = useState(false);
  const dispatch = useDispatch();



  const games = useSelector((state) => state.game);

  // Local state for sorting
  const [sortOrder, setSortOrder] = useState("desc");

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Local state for searching
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGames = games.filter((game) =>
    game.name.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const sortedGames = [...filteredGames].sort((a, b) => {
    const sortOrderFactor = sortOrder === "asc" ? 1 : -1;
    return sortOrderFactor * (a.no_of_launches - b.no_of_launches);
  });
  const [recentPlayers, setrecentPlayers] = useState([]);
  const [playerChartData, setPlayerChartData] = useState([]);

  useEffect(() => {
    getSystemData();
    getRecentPlayers();
    getChartData();
    dispatch(getStudioGames());
  }, [dispatch]);

  const getSystemData = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        "api/admin/adminAllStatistics",
        http.generateConfig(token)
      );
      if (res && res.result) {
        setOverallStats({ ...res.result });
        setLoading(false); // Mark loading as complete
      }
    } catch (error) {
      console.error("Error fetching system data:", error);
      setLoading(false); // Mark loading as complete even if there's an error
    }
  };
  const getRecentPlayers = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        "api/admin/recentPlayers",
        http.generateConfig(token)
      );

      if (res && res.result) {
        setrecentPlayers(res.result);
        console.log(res.result, "THE RESULT");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching system data:", error);
      setLoading(false);
    }
  };

  const getChartData = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        "api/admin/playerChartData",
        http.generateConfig(token)
      );
      console.log(res);

      if (res && res.result) {
        setPlayerChartData(res.result);
        setLoading(false); // Mark loading as complete
      }
    } catch (error) {
      console.error("Error fetching system data:", error);
      setLoading(false); // Mark loading as complete even if there's an error
    }
  };

  const onSideMenu = (active) => {
    setSideMenu(active);
  };
  const togglePan = () => {
    setEnablePan(!enablePan);
  };

  const statsStyle = {
    backgroundColor: "#f8f8f8",
    border: "1px solid #ddd",
    padding: "20px",
    margin: "20px 0",
  };

  const chartData = playerChartData.map((player) => ({
    x: new Date(player.date).getTime(),
    y: player.count,
  }));
  const chartOptions = {
    chart: {
      id: "line-chart",

    },
    xaxis: {
      type: "datetime",
    },
    stroke: {
      curve: "smooth",
      colors: ["#04542D"],
      width: 4,
      show: true,
      dashArray: 0,
    },

  };

  const chartSeries = [
    {
      name: "Players Joined",
      data: chartData,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
            }`}
        >
          {/* {loading && <Loader message="Loading..." />}
           */}
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="main-content-header">
                <Breadcrumb>
                  <h1>Home</h1>
                </Breadcrumb>

              </div>
              <div className="">
                <Row>
                  <Col md={12}>
                    <DashboardStats
                      statistics={[
                        {
                          title: "Total Games",
                          count: overallStats.totalGames,
                        },
                        {
                          title: "Total Players",
                          count: overallStats.totalPlayers,
                        },
                        {
                          title: "Total Duration",
                          count: `${overallStats && overallStats.totalDuration ?
                            `${overallStats.totalDuration.hours || 0}h ${overallStats.totalDuration.minutes || 0}m ${overallStats.totalDuration.seconds || 0}s` :
                            '0h 0m 0s'}`,
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Container className="mt-4">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div
                        className="mb-2"
                        style={{
                          backgroundColor: "#fff",
                          width: "720px",
                          height: "390px",

                          borderRadius: "15px",
                        }}
                      >
                        <ReactApexChart
                          options={chartOptions}
                          series={chartSeries}
                          type="line"
                          height={380}
                        />
                      </div>

                      <div
                        style={{
                          backgroundColor: "#fff",
                          overflow: "auto",
                          width: "440px",
                          height: "390px",
                          borderRadius: "15px",
                        }}
                      >
                        <Row className="m-1 mb-4 p-1">
                          <Col md={9} style={{ textAlign: "left" }}>
                            <span style={{ fontSize: "20px", fontWeight: 600 }}>
                              Joined Recently
                            </span>
                          </Col>
                          <Col>
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#04542D",
                                cursor: "pointer",
                              }}
                            >
                              <Link
                                to="/user"
                                style={{
                                  textDecoration: "none",
                                  color: "#04542D",
                                }}
                              >
                                All Users
                              </Link>
                            </span>
                          </Col>
                        </Row>

                        {recentPlayers.map((player, index) => (
                          <Row
                            key={index}
                            className="mb-3"
                            style={{ margin: 0, paddingRight: "10px" }}
                          >
                            <Col
                              md={7}
                              style={{ padding: "15px", textAlign: "left" }}
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                {" "}
                                {player.name ? player.name : "-"}
                              </span>
                              <p
                                style={{
                                  fontSize: "10px",
                                  fontWeight: 400,
                                  color: "#A3A3A3",
                                  margin: 0,
                                }}
                              >
                                {" "}
                                {player.email ? player.email : player.mobile}
                              </p>
                            </Col>
                            <Col style={{ padding: "5px", textAlign: "right" }}>
                              <div>
                                <span
                                  style={{
                                    color: "#1D2129",
                                    fontSize: "16px",
                                    lineHeight: "16px",
                                    fontWeight: 600,
                                    margin: 0,
                                    color: "#04542D",
                                  }}
                                >
                                  {player.goldFlake_earned}
                                  {"  "}
                                  <img src={tokenImage} height={15} />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </div>
                  </Container>
                </Row>

                <Row>
                  <Container
                    fluid
                    className="p-2 mt-5"
                    style={{ backgroundColor: "#fff", borderRadius: "15px" }}
                  >
                    <h5>Games</h5>

                    <div  >


                      <FormControl
                        type="text"
                        placeholder="Search by Name"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{ marginBottom: "4px" }}
                      />
                    </div>
                    {sortedGames.length > 0 ? (
                      <>
                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          <Table
                            hover
                            responsive
                            className="text-center"
                            style={{ margin: "0 auto" }}
                          >
                            <thead>
                              <tr>
                                <th onClick={toggleSortOrder}>Name</th>
                                <th>Category</th>
                                <th>packageName</th>
                                <th onClick={toggleSortOrder}>Launches</th>
                                <th>installs</th>
                              </tr>
                            </thead>

                            <tbody>
                              {sortedGames.map((game, index) => (
                                <tr key={index}>
                                  <td>
                                    <span
                                      style={{
                                        display: "flex",
                                        width: "50%",
                                        justifyContent: "space-between",
                                        margin: "0 auto",
                                      }}
                                    >
                                      <LazyLoadImage
                                        src={game.game_icon}
                                        alt="game"
                                        style={{ borderRadius: "32px" }}
                                        height={35}
                                        width={35}
                                      />
                                      <span className="gameName">
                                        <Link to={`/gameProfile/${game.id}`}>
                                          {game.name}
                                        </Link>
                                      </span>
                                    </span>
                                  </td>
                                  <td>{game.gameCategory}</td>
                                  <td>{game.packageName}</td>
                                  <td>{game.no_of_launches}</td>
                                  <td>{game.no_of_install}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <>no game found</>
                    )}
                  </Container>
                </Row>
              </div>

              <div className="flex-grow-1"></div>
              <Footer />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
