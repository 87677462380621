import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";


// import PrivateRoute from './Auth/privateRoutes'
import { createBrowserHistory } from "history";

import { ActiveUsers, AddGame, AddUser, GamePage } from "./pages";

// import { socket } from './socket';

import ForgetPassword from "./Auth/ForgetPassword";
import ForgetPasswordAuth from "./Auth/ForgetPasswordAuth";
import Suspended from "./Auth/Suspended";
import Login from "./Auth/login";
import PrivateRoute from "./Auth/privateRoutes";
import Signup from "./Auth/signup";
import Dashboard from "./pages/Dashboard";
import User from "./pages/User";
import NotFound from "./pages/NotFound";
import Reward from "./pages/Reward";
import GameStudio from "./pages/GameStudio";
import GameProfilePage from "./pages/GameProfilePage";
import Activity from "./pages/Activity";
import ActivityDetail from "./pages/ActivityDetail";
import Security from "./pages/Security";
import Impression from "./pages/impressions";
import Fraud from "./pages/fraud";
import Record from "./pages/record";
import MessageOfTheDay from "./pages/message";
import FailedAttempts from "./pages/failedLogin";
import SystemVariables from "./pages/systemVariables";
import WeeklyTopPlayers from "./pages/WeelyTopPlayers";
import Tasks from "./pages/Tasks";
// import { useState } from "react";
function App() {
  const history = createBrowserHistory();

  // const [isConnected, setIsConnected] = useState(socket.connected);

  return (
    <div className="App">
      <BrowserRouter history={history}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          {/* <Route path="*" elememnt={<NotFound />} /> */}
          <Route
            path="/game"
            element={
              <PrivateRoute>
                <GamePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/notify"
            element={
              <PrivateRoute>
                <MessageOfTheDay />
              </PrivateRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path="/gameStudio"
            element={
              <PrivateRoute>
                <GameStudio />
              </PrivateRoute>
            }
          />
          <Route
            path="/reward"
            element={
              <PrivateRoute>
                <Reward />
              </PrivateRoute>
            }
          />
          <Route
            path="/security"
            element={
              <PrivateRoute>
                <Security />
              </PrivateRoute>
            }
          />
          <Route
            path="/addGame"
            element={
              <PrivateRoute>
                <AddGame />
              </PrivateRoute>
            }
          />
          <Route
            path="/gameProfile/:id"
            element={
              <PrivateRoute>
                <GameProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/addUser"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />

          <Route
            path='/activeUsers'
            element={
              <PrivateRoute>
                <ActiveUsers />
              </PrivateRoute>
            }
          />
          <Route
            path='/impressions'
            element={
              <PrivateRoute>
                <Impression />
              </PrivateRoute>
            }
          />
          <Route
            path='/fraud'
            element={
              <PrivateRoute>
                <Fraud />
              </PrivateRoute>
            }
          />
          <Route
            path='/record'
            element={
              <PrivateRoute>
                <Record />
              </PrivateRoute>
            }
          />
          <Route
            path={`/Tasks`}
            element={
              <PrivateRoute>
                <Tasks />
              </PrivateRoute>
            }
          />

          <Route
            path="/forget-password"
            element={
              // <PrivateRoute>
              <ForgetPassword />
              // </PrivateRoute>
            }
          />
          <Route
            path={`/create-new-password/:paramsToken`}
            element={
              // <PrivateRoute>
              <ForgetPasswordAuth />
              // </PrivateRoute>
            }
          />

          <Route
            path='/failedLogin'
            element={
              <PrivateRoute>
                < FailedAttempts />
              </PrivateRoute>
            }
          />

          <Route
            path={`/suspended`}
            element={
              // <PrivateRoute>
              <Suspended />
              // </PrivateRoute>
            }
          />

          <Route path={"/activity"} element={<Activity />} />
          <Route path={"/activitydetail/:id"} element={<ActivityDetail />} />
          <Route
            path="*"
            element={
              // <PublicRoute>
              <NotFound />
              // </PublicRoute>
            }
          />
          <Route path={"/systemVariables"}
            element={<SystemVariables />}
          />

          <Route path={"/topPlayers"}
            element={<WeeklyTopPlayers />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
