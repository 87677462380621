import http from "../../utils/Api";
import { allTask, updateTask } from "../actionTypes/types";

const accessToken = localStorage.getItem("accessToken");

const getAllTask = () => async (dispatch) => {
  try {
    const res = await http.get(
      `/api/admin/getAllTasks`,
      http.generateConfig(accessToken)
    );
    if (res && res.result) {
      dispatch({
        type: allTask,
        payload: res.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const updateTaskAction = (data, id) => async (dispatch) => {
  try {
    console.log("data-->" , data)
    const res = await http.put(
      `/api/admin/updateTaskById/${id}`,
      data,
      http.generateConfig(accessToken)
    );
    if (res) {
      console.log("Update task action--->", res);

      dispatch({
        type: updateTask,
        payload: res.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export { getAllTask, updateTaskAction };
