import React, { useState, useEffect } from 'react';
import axios from 'axios';
import http from '../utils/Api';
import Navigation from "./../components/Navigation/Navigation";
import Footer from './Footer/Footer';
import "./notify.css"


export default function MessageOfTheDay() {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [body, setBody] = useState('');
  const [title, setTitle] = useState("");
  const [updated, setUpdated] = useState("");
  const [sideMenu, setSideMenu] = useState(true);

  useEffect(() => {
    const fetchMessage = async () => {
      try {

        // const response = await axios.get('/api/messageOfDay');
        const response = await axios.get('https://production.earnscape.io/api/messageOfDay');

        console.log(response, 'THE RESONSE MESSAGE OF DAT')
        setUpdated(response.data.message);
      } catch (error) {
        setError(error.message);
      } finally {


        setLoading(false);
      }
    };

    fetchMessage();
  }, []);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };


  const handleUpdateMessage = async () => {
    try {
      setLoading(true);

      http.defaults.headers['currentversion'] = '1.1.50'
      const token = localStorage.getItem("accessToken");

      const response = await http.put("/api/admin/updateMessageOfTheDay/1", { message },http.generateConfig(token));
      console.log(response, 'Message sent successfully.');

      if (response) {
        setUpdated(response.result.message)
      }
      setMessage("");

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);

    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);


    try {
      await sendNotificationToAllPlayers(title, body);
      console.log('Notification sent successfully.');

      setTitle('');
      setBody('');
    } catch (error) {
      console.error('Error sending notification:', error);
      setError('Error sending notification. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const API_ENDPOINT = '/api/admin/sendNotificationToAllPlayers';

  const sendNotificationToAllPlayers = async (title, body) => {
    try {
      const response = await http.post(API_ENDPOINT, {
        title: title,
        body: body
      });
      console.log('Notification sent successfully:', response.data);
      return response.data;
      setTitle(response.data.title)
      setBody(response.data.body)
    } catch (error) {
      console.error('Error sending notification:', error);
      throw error;
    }
  };




  // const handleChange = (event) => {
  //   setMessage(event.target.value);
  // };


  return (
    <>
      <div className="pg-wrap">
        <Navigation onClick={onSideMenu} />
        <div className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}>

          <div className="container d-flex justify-content-center  mt-4 mb-4" >
            <div className="">


              <div className="card  what border border-gray-300" style={{
                width: "43rem",
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
                
              }}>
                <div className="card-body">
                  <h2 className="my-3  py-2 card-title" style={{
                    fontSize: "30px",
                    color: "rgb(121, 121, 121)",
                    fontWeight: 500,
                    paddingLeft: "5px",
                  }}>
                    Message of The Day
                  </h2>
                  <input
                    type="text"
                    value={message}
                    id="msg"
                    placeholder="Enter a New Message"
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-control"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#fff",
                      boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
                      paddingBottom: "10px",
                      paddingTop: "10px",
                    }}
                  />

                  <div className="card-btn d-flex justify-content-start my-3">
                    <button onClick={handleUpdateMessage} style={{
                      borderRadius: "10px",
                      color: "rgb(121, 121, 121)",
                      backgroundColor: "#fff",
                      padding: "7px 10px",
                      boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
                      borderColor: "rgb(121, 121, 121)",
                      marginBottom: "3px"
                    }}>
                      Update Message
                    </button>
                  </div>
                  <div className="card-subtitle mb-3 d-flex justify-content-start ">
                    <label htmlFor="msg" style={{
                      fontSize: "25px",
                      color: "rgb(121, 121, 121)",
                      fontWeight: 500,
                      paddingLeft: "5px",
                      paddingTop: "5px"
                    }}>Message:</label>

                    <div className='msg' style={{
                      backgroundColor: "#f0f0f0",
                      fontSize: "20px",
                      color: "#04542D",
                      fontWeight: 400,
                      padding: "10px",
                      borderRadius: "10px",
                      marginLeft: "10px"
                    }}>{updated}</div>
                  </div>
                </div>
              </div>
              <div className="card border border-gray-300 mt-5" style={{
                width: "43rem",
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",      
              }}>
                <div className="card-body">
                  
                    <h2 className="card-title my-3 py-2" style={{
                      fontSize: "30px",
                      color: "rgb(121, 121, 121)",
                      fontWeight: 500,
                      paddingLeft: "5px",
                    }}>
                      Notification
                    </h2>
                  
                  <div className="row mb-4">
                    <div style={{ marginBottom: "10px" }}>
                      <input
                        type="text"
                        value={title}
                        placeholder="Title"
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control"
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                          boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      />
                    </div>
                    <div className="">
                      <textarea
                        value={body}
                        placeholder="Body"
                        onChange={(e) => setBody(e.target.value)}
                        className="form-control"
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                          boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      />
                    </div>
                    <div className="card-btn d-flex justify-content-start my-3">
                      <button onClick={handleSubmit} style={{
                        borderRadius: "10px",
                        color: "rgb(121, 121, 121)",
                        backgroundColor: "#fff",
                        padding: "7px 10px",
                        boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
                        borderColor: "rgb(121, 121, 121)",
                        marginLeft: ""
                      }}>
                        Send Notification
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div >
    </>
  );

}




