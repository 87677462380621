export const add = "addGame";
export const get = "getGame";
export const getAll = "getStudioGames";
export const update = "updateGame";
export const remove = "removeGame";
export const mark = "markasfeatured";
export const unmark = "unmarkasfeatured";
export const sus = "markPlayerAsSus"
export const notSuspicious = "unMarkPlayerAsSus"

export const addUsers = "addUser";
export const getUser = "getUser";
export const getAllUsers = "getAllUsers";
export const getAllUsersPermission = "getAllUsersPermission";
export const updateUsers = "updateUser";
export const removeUser = "removeuser";
export const clearUserDevice ="clearUserDevice"
export const deleteUserAccount = "deleteUserAccount"

export const getSingleUserById = "getSingleUserById";

export const getGameStudios = "getGameStudio";
export const updateGameStudios = "updateGameStudio";

export const loginType = "login";
export const registerType = "register";


export const addReward = "addReward";
export const getReward = "getReward";
export const getAllRewards = "getStudioRewards";
export const updateReward = "updateReward";
export const removeReward = "removeReward";


//Task types
export const allTask = 'allTask';
export const updateTask = 'updateTask;'