import React, { useState, useEffect } from "react";
import { Form, Spinner, Button,Modal, FormControl } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { markGameFeatured, unmarkGameFeatured } from "../service/actions/gameActions";
import { useDispatch } from "react-redux";
import GameFeatureButton from "../components/common/featureType";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const GameList = ({ games, onDeleteGame, onToggleState, }) => {
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0); // Page numbers start from 1
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [featuredGames, setFeaturedGames] = useState([]);
  const dispatch = useDispatch()
  
  

  // const onMarkGame = (game) => {
  //   dispatch(markGameFeatured(game));

  // }
  // const onUnMarkGame = (game) => {
  //   dispatch(unmarkGameFeatured(game))
  // }
  const onMarkGame = (gameId, featureType) => {
    dispatch(markGameFeatured(gameId, featureType));
  };

  const onUnMarkGame = (gameId) => {
    dispatch(unmarkGameFeatured(gameId));
  };



  useEffect(() => {
    if (games.length > 0) {
      setLoading(false);
    }
  }, [games]);

  // Filter games based on the search term
  // const filteredGames = games.filter((game) =>
  // {
  //   console.log("game name", game.name);
  //   console.log("search", searchTerm);
  //   (game.name || "").includes(searchTerm)
  // }
  // );

  const filteredGames = games.filter((game) => {
    
    return (game.name.toLowerCase().startsWith(searchTerm.toLocaleLowerCase()));
  });
  

  // Calculate the index range for the current page
  //const indexOfLastGame = currentPage * itemsPerPage;
  //const indexOfFirstGame = indexOfLastGame - itemsPerPage;
  const offset = currentPage * itemsPerPage;
  const currentGames = filteredGames.slice(offset, offset + itemsPerPage);


  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    console.log("Selected Page:", selectedPage); // Add this log
    setCurrentPage(selectedPage);
  };
  const pageCount = Math.ceil(filteredGames.length / itemsPerPage);


  //  const paginatedUsers = filteredUsers.slice(offset, offset + itemsPerPage);

  //  Handle page change
  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // Handle search input change
  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  //   // Reset the current page to 1 when searching
  //   setCurrentPage(1);
  // };

  // Calculate the last page
  //const lastPage = Math.ceil(filteredGames.length / itemsPerPage);

  // Calculate the number of page numbers to display
  // const displayPageCount = 3; // Change this number as needed

  // Create an array of page numbers based on the current page and display count
  // const pageNumbers = [];
  // const startPage = Math.max(1, currentPage - Math.floor(displayPageCount / 2));
  // const endPage = Math.min(lastPage, startPage + displayPageCount - 1);

  // for (let i = startPage; i <= endPage; i++) {
  //   pageNumbers.push(i);
  // }

 

  return (
    <div className="game-list">
      <div className="form-content-adduser container-fluid">
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <div>
            <h3>Games</h3>



            <div className="pagination-parent container-fluid">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousClassName={"previous"}
                nextClassName={"next"}
                activeClassName={"active"} 
                activeLinkClassName={"active"}
                previousLinkClassName={"previous"}
                nextLinkClassName={"next"}
                disabledClassName={"disabled"}
              />
            </div>


            <FormControl
              type="text"
              placeholder="Search by game name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th> Icon </th>
                  <th>Title</th>
                  <th> Status </th>
                  <th>Feature</th>
                </tr>
              </thead>
              <tbody>
                {currentGames.map((game) => (
                  <tr key={game.id}>
                    <td style={{ width: '250px', maxWidth: "250", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <LazyLoadImage
                        src={game.game_icon}
                        alt={`${game.name} Icon`}
                        width="50"
                        style={{ borderRadius: "50%" }} // This makes the image round
                      />
                    </td>

                    <td style={{ cursor: "pointer", width: '350px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Link to={`/gameProfile/${game.id}`}>{game.name}</Link>
                    </td>
                    <td style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Button
                        variant={game.isApprove ? "danger" : "success"}
                        onClick={() => onToggleState(game)}
                      >
                        {game.isApprove ? "Suspend" : "Activate"}
                      </Button>
                    </td>


                    <td style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <GameFeatureButton
                        game={game}
                        onUnMarkGame={onUnMarkGame}
                        featuredGames={featuredGames}
                        setFeaturedGames={setFeaturedGames}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameList;


